import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { isMobile } from 'react-device-detect';
import { ResponsiveVideo, Dialog } from 'components';
import CarouselPlayButton from 'images/Home/CarouselPlayButton';
import ChevronLeft from 'images/Home/ChevronLeft';

const ClientVideoCarousel = ({ handleSplashScreen }) => {
  const swipeConfidenceThreshold = 5000;
  const swipePower = (offset, velocity) => Math.abs(offset) * velocity;
  const [[page, direction], setPage] = useState([0, 0]);
  const [showCarouselVideoDialog, setShowCarouselVideoDialog] = useState(false);
  const carouselVideoDialogRef = useRef(null);

  const closeCarouselVideoDialog = () => {
    carouselVideoDialogRef.current.setAttribute('data-state', 'close');
    carouselVideoDialogRef.current.children[0].setAttribute('data-state', 'close');
    setShowCarouselVideoDialog(false);
  };

  const slides = [
    {
      name: 'Karen Deenihan',
      client: 'Aviva Life & Pensions Ireland DAC',
      video: 'aviva_carousel'
    },
    {
      name: 'Maeve Desmond',
      client: 'Ardagh Group',
      video: 'ardagh_carousel'
    },
    {
      name: 'Michael Whelan',
      client: 'IRFU Charitable Trust',
      video: 'irfu_carousel'
    }
  ];
  
  const index = wrap(0, slides.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const { clientLogos, clientThumbnails, mobileClientThumbnails, mobileClientLogos } = useStaticQuery(
    graphql`
      query {
        clientLogos: allFile(
          filter: { relativeDirectory: { regex: "/Home/ClientCarouselLogos/" } }
          sort: { fields: [relativeDirectory], order: ASC }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fixed(height: 82, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        }
        mobileClientLogos: allFile(
          filter: { relativeDirectory: { regex: "/Home/ClientCarouselLogos/" } }
          sort: { fields: [relativeDirectory], order: ASC }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fixed(height: 40, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        }
        mobileClientThumbnails: allFile(
          filter: { relativeDirectory: { regex: "/Home/ClientCarouselThumbnails/" } }
          sort: { fields: [relativeDirectory], order: ASC }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fixed(width: 308, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        }
        clientThumbnails: allFile(
          filter: { relativeDirectory: { regex: "/Home/ClientCarouselThumbnails/" } }
          sort: { fields: [relativeDirectory], order: ASC }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fixed(width: 442, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  );

  const thumbnails = isMobile ? mobileClientThumbnails : clientThumbnails;
  const logos = isMobile ? mobileClientLogos : clientLogos;


  useEffect(() => {
    if(!showCarouselVideoDialog){
    const timer = setTimeout(() => paginate(1), 5000);
    return () => clearTimeout(timer);
    }
  }, [page, showCarouselVideoDialog]);

  return (
    <Container>
      <Previous
        onClick={() => paginate(-1)}
        whileTap={{ scale: 0.95 }}
        whileHover={{ scale: 1.075 }}>
        <ChevronLeft />
      </Previous>

        <Slide
          key={index}
          custom={direction}
          initial={{ opacity: 0, x: direction * 10 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 0.5 } }}
          transition={{
            x: { type: 'spring' },
            opacity: { duration: 1 }
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}>
          <div style={{ position: 'relative', width: 'max-content' }}>
            <SVGContainer
              role="button"
              onClick={() => setShowCarouselVideoDialog(true)}
              whileTap={{ scale: 0.95 }}
              whileHover={{ scale: 1.05 }}>
              <CarouselPlayButton />
            </SVGContainer>
            <VideoThumbnail
              fixed={thumbnails.edges[index].node.childImageSharp.fixed}
              key={thumbnails.edges[index].node.name}
              alt={thumbnails.edges[index].node.name.replace(/-/g, ' ')}
            />
          </div>
          <Descriptor>
            <ClientLogo
              fixed={logos.edges[index].node.childImageSharp.fixed}
              key={logos.edges[index].node.name}
              alt={logos.edges[index].node.name.replace(/-/g, ' ')}
            />
            <p>
              {slides[index].name}
              <br />
              {slides[index].client}
            </p>
          </Descriptor>
        </Slide>
      <Dialog
        ref={carouselVideoDialogRef}
        onDismiss={closeCarouselVideoDialog}
        isVisible={showCarouselVideoDialog}>
        <VideoContainer>
          <ResponsiveVideo
            hideControls
            videoTitle={slides[index].video}
            handleSplashScreen={handleSplashScreen}
          />
        </VideoContainer>
      </Dialog>
      <Next onClick={() => paginate(1)} whileTap={{ scale: 0.95 }} whileHover={{ scale: 1.075 }}>
        <ChevronLeft />
      </Next>
    </Container>
  );
};

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 3rem 1.25rem;
  gap: 2.625rem;
  max-width: 480px;
  position: relative;
  @media screen and (min-width: 1024px) {
    max-width: 1000px;
    margin: 3rem auto;
  }
`;


const Slide = styled(motion.div)`
  background: rgba(217, 217, 217, 0.3);
  border-radius: 1.25rem;
  display: grid;
  gap: 2rem;
  padding: 1.5rem 1.25rem;
  width: 100%;
  max-width: 480px;
  p {
    font-size: 1.25rem;
    font-family: Proxima Nova;
    font-weight: 300;
    line-height: 1.25;
  }
  @media screen and (min-width: 1024px) {
    gap: 4rem;
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem 1.625rem;
    width: auto;
    max-width: 1000px;
  }
`;

const Direction = styled(motion.button)`
  align-items: center;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: none;
  height: 48px;
  justify-content: center;
  width: 48px;
  outline: none;
  position: absolute;
  z-index: 10;
  svg {
    height: 22px;
    width: 22x;
  }
  @media screen and (min-width: 780px) {
    display: flex;
  }
`;

const Previous = styled(Direction)`
  background: linear-gradient(270deg, #ffef00 0%, #e4097e 65%);
  padding-right: 0.5rem;
  left: -6rem;
`;

const Next = styled(Direction)`
  background: linear-gradient(-270deg, #ffef00 0%, #e4097e 65%);
  padding-left: 0.5rem;
  right: -6rem;
  svg {
    transform: rotate(180deg);
  }
`;

const Descriptor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  p {
    font-size: 1.25rem;
    font-family: Proxima Nova;
    font-weight: 300;
    line-height: 1.25;
  }
  @media screen and (min-width: 1024px) {
    justify-content: center;
  }
`;

const VideoThumbnail = styled(Img)`
  width: 100%;
  border-radius: 10px;
  position: relative;
  z-index: 0;
  @media screen and (min-width: 1024px) {
    width: 442px;
  }
`;

const ClientLogo = styled(Img)`
  max-height: 82px;
  width: 100%;
`;

const SVGContainer = styled(motion.div)`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 78px;
    transform: translate(-50%, -50%);
    width: 78px;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 320px;
  @media screen and (min-width: 1024px) {
    min-width: 720px;
  }
`;

export default ClientVideoCarousel;
