import React, { Component, useState } from 'react';
import { slide as BurgerMenu } from 'react-burger-menu';
import { isMobile } from 'react-device-detect';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import LogoSVG from 'images/Shared/Header/get-broadcasting-logo.svg';
import DropdownArrow from 'images/Shared/Header/DropdownArrow';
import { AnimatePresence, motion } from 'framer-motion';
import './hamburger.css';

export default class Header extends Component {
  state = {
    isOpen: false,
    isServicesOpen: false,
    componentIsReady: false
  };

  componentDidMount() {
    this.setState({
      componentIsReady: true
    });
  }

  handleStateChange = (state) => {
    this.setState({ isOpen: state.isOpen, isServicesOpen: state.isServicesOpen });
  };

  closeMenu = () => {
    this.setState({ isOpen: false });
  };

  toggleDropdownState = () => {
    this.setState((prevState) => ({
      isServicesOpen: !prevState.isServicesOpen
    }));
  };

  render() {
    const { headerBackgroundIsTransparent, pathname } = this.props;
    const { isOpen, isServicesOpen, componentIsReady } = this.state;
    const makeLinksBlack =
      !headerBackgroundIsTransparent &&
      (pathname === '/services/' ||
        pathname === '/contact-us/' ||
        pathname?.includes('/portfolio/'));

    return (
      componentIsReady &&
      (isMobile ? (
        <MobileHeader headerBackgroundIsTransparent={headerBackgroundIsTransparent}>
          <Link
            to="/"
            state={{
              hideSplashScreen: true
            }}>
            <StyledLogo src={LogoSVG} isMobile={isMobile} alt="Get Broadcasting Logo" />
          </Link>
          <BurgerMenu
            width="60vw"
            isOpen={isOpen}
            onStateChange={(state) => this.handleStateChange(state)}>
            <HamburgerMenuLink
              onClick={() => this.closeMenu()}
              activeStyle={{ ...mobileActiveStyle }}
              to="/"
              state={{
                hideSplashScreen: true
              }}>
              Home
            </HamburgerMenuLink>
            <HamburgerMenuLink
              onClick={() => {
                this.closeMenu();
              }}
              activeStyle={{ ...mobileActiveStyle }}
              to="/services/live-stream-events/">
              Events
            </HamburgerMenuLink>
{/*             <HamburgerMenuExternalLink
              href="https://virtuosity.ie"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                this.closeMenu();
              }}>
              Virtuosity
            </HamburgerMenuExternalLink> */}
            <HamburgerMenuLink
              onClick={() => {
                this.closeMenu();
              }}
              activeStyle={{ ...mobileActiveStyle }}
              to="/services/video-animation/">
              Video
            </HamburgerMenuLink>
            <HamburgerMenuLink
              onClick={() => {
                this.closeMenu();
              }}
              activeStyle={{ ...mobileActiveStyle }}
              to="/portfolio/">
              Our Work
            </HamburgerMenuLink>
            <HamburgerMenuLink
              onClick={() => {
                this.closeMenu();
              }}
              activeStyle={{ ...mobileActiveStyle }}
              to="/vlog/">
              Vlog
            </HamburgerMenuLink>
            <HamburgerMenuLink
              onClick={() => {
                this.closeMenu();
              }}
              activeStyle={{ ...mobileActiveStyle }}
              to="/about-us/">
              About Us
            </HamburgerMenuLink>
            <HamburgerMenuLink
              onClick={() => this.closeMenu()}
              activeStyle={{ ...mobileActiveStyle }}
              to="/contact-us/">
              Contact
            </HamburgerMenuLink>
          </BurgerMenu>
        </MobileHeader>
      ) : (
        <DesktopHeader>
          <Nav pathname={pathname} headerBackgroundIsTransparent={headerBackgroundIsTransparent}>
            <Link
              to="/"
              state={{
                hideSplashScreen: true
              }}>
              <StyledLogo src={LogoSVG} alt="Get Broadcasting Logo" />
            </Link>
            <DesktopHeaderPageLinks pathname={pathname}>
              <DesktopHeaderPageLink
                to="/"
                makeLinksBlack={makeLinksBlack}
                activeStyle={desktopHeaderActiveStyle(makeLinksBlack)}
                state={{
                  hideSplashScreen: true
                }}>
                Home
              </DesktopHeaderPageLink>
              <DesktopHeaderPageLink
                to="/services/live-stream-events/"
                makeLinksBlack={makeLinksBlack}
                activeStyle={desktopHeaderActiveStyle(makeLinksBlack)}>
                Events
              </DesktopHeaderPageLink>
{/*               <DesktopHeaderPageExternalLink
                href="https://virtuosity.ie"
                target="_blank"
                rel="noopener noreferrer"
                makeLinksBlack={makeLinksBlack}>
                Virtuosity
              </DesktopHeaderPageExternalLink> */}
              <DesktopHeaderPageLink
                to="/services/video-animation/"
                makeLinksBlack={makeLinksBlack}
                activeStyle={desktopHeaderActiveStyle(makeLinksBlack)}>
                Video
              </DesktopHeaderPageLink>
              <DesktopHeaderPageLink
                to="/portfolio/"
                makeLinksBlack={makeLinksBlack}
                activeStyle={desktopHeaderActiveStyle(makeLinksBlack)}>
                Our Work
              </DesktopHeaderPageLink>
              <DesktopHeaderPageLink
                to="/vlog/"
                makeLinksBlack={makeLinksBlack}
                activeStyle={desktopHeaderActiveStyle(makeLinksBlack)}>
                Vlog
              </DesktopHeaderPageLink>
              <DesktopHeaderPageLink
                to="/about-us/"
                makeLinksBlack={makeLinksBlack}
                activeStyle={desktopHeaderActiveStyle(makeLinksBlack)}>
                About Us
              </DesktopHeaderPageLink>
              <DesktopHeaderPageLink to="/contact-us/">
                <div>
                  <span>Contact Us</span>
                </div>
              </DesktopHeaderPageLink>
            </DesktopHeaderPageLinks>
          </Nav>
        </DesktopHeader>
      ))
    );
  }
}

const StyledLogo = styled.img`
  ${({ isMobile }) =>
    isMobile
      ? css`
          position: absolute;
          right: 10px;
          top: 6px;
          width: 48px;
        `
      : css`
          height: 72px;
          position: relative;
          right: initial;
          top: 3px;
          width: 62px;
        `}
`;

const mobileActiveStyle = {
  color: '#0095cd'
};

const desktopHeaderActiveStyle = (makeLinksBlack) => ({
  borderBottomColor: '#E4097E',
  borderBottomStyle: 'solid',
  borderBottomWidth: '3px',
  color: makeLinksBlack ? '#000' : '#fff',
  position: 'relative',
  textDecoration: 'none',
  top: '0.23em',
  fontWeight: '700',
  paddingBottom: '0.225em',
  transition: 'color 0.2s'
});

const desktopHeaderDropdownActiveStyle = () => ({
  borderBottomColor: '#E4097E',
  borderBottomStyle: 'solid',
  borderBottomWidth: '2px',
  position: 'relative',
  textDecoration: 'none',
  fontWeight: '700',
  transition: 'color 0.2s'
});

const MobileHeader = styled.header`
  background-color: ${({ headerBackgroundIsTransparent }) =>
    headerBackgroundIsTransparent ? css`rgba(0, 0, 0, 1)` : css`rgba(0, 0, 0, 0.85)`};
  border-top: 5px solid #0095cd;
  border-bottom: 2px solid #0095cd;
  height: 71px;
  position: fixed;
  max-width: 1440px;
  transition: background-color 0.5s ease-in-out;
  width: 100%;
  z-index: 4;
  top: 0;
  left: 0;
`;

const HamburgerMenuLink = styled(Link)`
  font-family: proxima-nova, sans-serif;
  color: #fff;
  font-weight: 600;
  font-size: 1.625rem;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 1em;

  @media screen and (min-width: 360px) {
    font-size: 1.85rem;
  }

  @media screen and (min-width: 375px) {
    font-size: 2rem;
  }
`;

const HamburgerMenuExternalLink = styled.a`
  font-family: proxima-nova, sans-serif;
  color: #fff;
  font-weight: 600;
  font-size: 1.625rem;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 1em;

  @media screen and (min-width: 360px) {
    font-size: 1.85rem;
  }

  @media screen and (min-width: 375px) {
    font-size: 2rem;
  }
`;

const DesktopHeader = styled.header`
  max-width: 1440px;
  top: 0;
  width: 100%;
  z-index: 4;

  ${({ pathname }) =>
    pathname === '/'
      ? css`
          position: sticky;
        `
      : css`
          position: fixed;
        `};
`;

const Nav = styled.nav`
  background-color: ${({ headerBackgroundIsTransparent, pathname }) =>
    headerBackgroundIsTransparent
      ? 'rgba(000, 000, 000, 0.775)'
      : pathname === '/'
      ? 'rgba(000, 000, 000, 1)'
      : 'transparent'};
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.5em 2.5em;
  transition: background-color 250ms ease-in-out;
`;

const DesktopHeaderPageLinks = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-family: proxima-nova, sans-serif;
  line-height: 1.063em;
  letter-spacing: 0.01em;
  font-size: 1rem;
  padding: 0.25em 0.75em;
  text-decoration: none;
  width: 780px;

  a:not(:last-of-type) {
    position: relative;
  }

  a:last-of-type div {
    background: linear-gradient(109.77deg, #ffef00 62.69%, #e4097e 195.24%);
    border-radius: 20px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    color: #000;
    padding: 0.5em 1.5em;
    font-weight: 600;

    span {
      position: relative;
      top: 1px;
    }
  }
`;

const DesktopHeaderPageLink = styled(Link)`
  color: ${({ makeLinksBlack }) => (makeLinksBlack ? '#000' : '#fff')};
  text-decoration: none;

  &:visited {
    color: ${({ makeLinksBlack }) => (makeLinksBlack ? '#000' : '#fff')};
  }
`;

const DesktopHeaderPageExternalLink = styled.a`
  color: ${({ makeLinksBlack }) => (makeLinksBlack ? '#000' : '#fff')};
  text-decoration: none;

  &:visited {
    color: ${({ makeLinksBlack }) => (makeLinksBlack ? '#000' : '#fff')};
  }
`;

const DesktopHeaderPageButton = styled.button`
  position: relative;
  color: ${({ makeLinksBlack }) => (makeLinksBlack ? '#000' : '#fff')};
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: transparent;
  outline: none;
  border: none;
  font-family: proxima-nova, sans-serif;
  line-height: 1.063em;
  letter-spacing: 0.01em;
  font-size: 1rem;
  text-decoration: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  svg {
    height: 8px;
    width: 8px;
    opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
    transition: all 0.2s ease-in-out;
  }
  &:visited {
    color: ${({ makeLinksBlack }) => (makeLinksBlack ? '#000' : '#fff')};
  }
`;

const ServicesDropdown = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 3px;
  border-left: 2px solid #e4097e;
  background: transparent;
  width: 250%;
  ul {
    text-align: left;
    li {
      list-style-type: none;
      &:not(:first-of-type) {
        background: white;
        padding: 0.75em 0.25em 0.75em 1.5em;
        a {
          color: #000;
          line-height: 1.063em;
          letter-spacing: 0.01em;
          font-size: 1rem;
          text-decoration: none;
        }
      }
    }
  }
`;
